<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import { isNil } from 'lodash'

import NavBar from '@/components/NavBar'

export default {
  components: { NavBar },
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
          } else {
            this.$i18n.locale = 'fr'
          }
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss">

@import '@/theme/variables.scss';
@import '@/theme/glightbox.scss';
  
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    padding-top: 68px;
    @media screen and (max-width: 600px) {
      padding-top: 0;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

</style>

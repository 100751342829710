<template>
  <header class="navbar">
    <img src="@/assets/img/logo.svg" class="logo" alt="Logo lower my bill">

    <a v-if="$route.meta.lang && $route.meta.lang === 'en'" href="/fr" class="regular-text lang">Fr<span class="not-on-mobile">ançais</span></a>
    <a v-if="$route.meta.lang && $route.meta.lang === 'fr'"  href="/" class="regular-text lang">En<span class="not-on-mobile">glish</span></a>

    <div class="social">
      <p class="regular-text bold">{{ $t("Follow us on:") }}</p>
      <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook"></a>
      <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter"></a>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/typos.scss';

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0 0 0 3vw;
  z-index: 1000;

  .logo {
    width: 250px;
  }

  .lang {
    margin-left: auto;
    padding-right: 20px;
    font-weight: 600;

    .not-on-mobile {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .social {
    display: flex;
    align-items: center;
    background-color: $color1;
    padding: 20px 25px;

    .regular-text {
      color: $color3;
    }

    img {
      width: 30px;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 600px) {
    position: relative;
    .logo {
      width: 200px;
    }
    .cta {
      display: none;
    }
    .social {
      padding: 10px;
      .regular-text {
        display: none;
      }
    }
  }
}


</style>

<template>
  <div class="page-wrapper">
    <section class="hero" :class="{ 'is-fr': $route.meta.lang && $route.meta.lang === 'fr' }">
      <div class="data">
        <a v-scroll-to:-70 href="#next" data-inview="fadeInUp" data-delay="400"
          ><img src="@/assets/img/arrow-down.svg" class="arrow" alt="arrow down icon"
        /></a>
      </div>
      <div class="overlay"></div>
    </section>

    <section id="next" class="split">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title">{{ $t('More competition is needed') }}</h2>
        <p class="regular-text bold bigger">{{ $t('Canadians deserve better!') }}</p>
        <p
          class="regular-text"
          v-html="
            $t(
              'Most Canadians pay a lot for their mobile phone service. To get better rates, experts agree:<span> the presence of a new player, a strong 4th player, in a mobile market is essential to increase competition and lower prices.</span>.<br><br><span>Videotron</span>, a leader in telecommunications in Quebec, is determined to <span>shake up the market</span>. As a strong and solid 4th player, we are ready to expand our services and give the Big 3 some healthy competition in Western Canada and Ontario.'
            )
          "
        ></p>
        <div class="cta-wrap">
          <a href="http://eepurl.com/hLy4s5" target="_blank" class="cta">
            <p class="regular-text bold">{{ $t('Subscribe for email updates') }}</p>
            <img src="@/assets/img/arrow-right.svg" alt="Arrow" />
          </a>
        </div>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <img
          v-if="$route.meta.lang && $route.meta.lang === 'en'"
          src="@/assets/img/stats-visual.jpg"
          alt="The Big 3 mobiles providers Rogers, Bell, and Telu"
        />
        <img v-if="$route.meta.lang && $route.meta.lang === 'fr'" src="@/assets/img/3big_fr.jpg" alt="Les 3 grands fournisseurs" />
      </div>
    </section>

    <div class="green-wrap">
      <section class="split reverse">
        <div class="data" data-inview="slideLeft" data-delay="200">
          <h2 class="title">{{ $t('Competition from Videotron helps lower wireless prices') }}</h2>
          <div class="bloc">
            <p class="regular-text bold bigger">{{ $t('Advantage for consumers') }}</p>
            <p
              class="regular-text"
              v-html="
                $t(
                  'Where there is a strong 4th player, <span>Canadians pay 35-40% less than elsewhere in the country</span>, according to the Competition Bureau of Canada (2019)'
                )
              "
            ></p>
          </div>
          <div class="bloc">
            <p class="regular-text bold bigger">{{ $t('Target achieved') }}</p>
            <p
              class="regular-text"
              v-html="
                $t(
                  'Thanks to Videotron, Quebec is the first part of the country to achieve the government’s <span>25% price reduction</span> target for specific wireless plans'
                )
              "
            ></p>
          </div>
          <div class="bloc">
            <p class="regular-text bold bigger">{{ $t('Why lack of competition matters') }}</p>
            <p
              class="regular-text"
              v-html="
                $t(
                  'While advertising in Canadian newspapers, Bell is proposing in Vancouver, Edmonton, Calgary and Ottawa a 20gb wireless plan for 80$/month. In Montreal, Bell offers the same plan at 65$/month, which just happens to be what Videotron charges.'
                )
              "
            ></p>
          </div>
          <div class="cta-wrap">
            <div class="social">
              <p class="regular-text">{{ $t('Join us on:') }}</p>
              <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook" /></a>
              <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter" /></a>
            </div>
          </div>
        </div>
        <div class="img" data-inview="slideRight" data-delay="200">
          <img v-if="$route.meta.lang && $route.meta.lang === 'en'" src="@/assets/img/phone-bill-en.jpg" alt="Same wireless plan, different price. Why?" />
          <img
            v-if="$route.meta.lang && $route.meta.lang === 'fr'"
            src="@/assets/img/phone-bill-fr.jpg"
            alt="Même forfait sans fil, prix différent. Pourquoi ?"
          />
        </div>
        <!-- <div class="img" data-inview="slideLeft" data-delay="200">
          <img v-if="$route.meta.lang && $route.meta.lang === 'en'" src="@/assets/img/Map_Canada_web_va_72dpi_v4.png" alt="Canada">
          <img v-if="$route.meta.lang && $route.meta.lang === 'fr'" src="@/assets/img/Map_Canada_web_fr_72dpi_v4.png" alt="Canada">
          <div class="label">
            <p class="regular-text bold biggest">{{ $t("Acquisition of spectrum by Videotron across Canada") }}</p>
          </div>
        </div> -->
      </section>

      <section class="split">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title">{{ $t('Acquisition of spectrum by Videotron across Canada') }}</h2>
          <div class="bloc">
            <p
              class="regular-text"
              v-html="
                $t(
                  'Videotron invested $830 million in the last 5G spectrum auction to be able to move forward on its plans to bring healthy competition, more choices and lower prices to more Canadians.'
                )
              "
            ></p>
          </div>
          <div class="cta-wrap">
            <div class="social">
              <p class="regular-text">{{ $t('Join us on:') }}</p>
              <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook" /></a>
              <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter" /></a>
            </div>
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <img v-if="$route.meta.lang && $route.meta.lang === 'en'" src="@/assets/img/canada-map-en.jpg" alt="Canada" />
          <img v-if="$route.meta.lang && $route.meta.lang === 'fr'" src="@/assets/img/canada-map-fr.jpg" alt="Canada" />
        </div>
      </section>
    </div>

    <section class="letter">
      <div class="img" data-inview="revealRight" data-delay="200">
        <img src="@/assets/img/who-we-are.jpg" alt="Montréal" class="desktop" />
        <img src="@/assets/img/who-we-are-mobile.jpg" alt="Montréal" class="mobile" />
      </div>
      <div class="data">
        <h2 class="title">{{ $t('Who we are') }}</h2>
        <p class="regular-text">
          <a href="https://corpo.videotron.com/en/company/our-company" target="_blank">Videotron</a>
          {{
            $t(
              'is an integrated communications company engaged in cable television, interactive multimedia development, Internet access, cable telephone and wireless telephone services. As a Quebecor company, established for more than five decades, Videotron is part of a strong, creative, and diversified family business.'
            )
          }}
          <br /><br />
          {{
            $t(
              'As Quebec’s most respected telecommunications provider for the last 16 years (2021 Léger Reputation survey), Videotron broke the Big 3 oligopoly in Quebec by becoming a 4th major player in the wireless market, offering competitive mobile phone service there since 2006.'
            )
          }}
          <br /><br />
          {{
            $t(
              'The company has invested billions over decades to create distinctive offerings and services, and to build out a reliable, powerful, robust telecommunications network. Videotron was even the first in the country to create an all-digital telecom brand with Fizz.'
            )
          }}
        </p>
        <!-- <div class="cta-wrap">
            <div class="social">
            <p class="regular-text">To say informed follow us on:</p>
            <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook"></a>
            <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter"></a>
          </div>
        </div> -->
      </div>
    </section>

    <section class="top-arguments">
      <div class="box" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/videotron-icon.png" alt="Videotron" />
        <p class="regular-text">{{ $t('$3B+ INVESTED in wireless') }}</p>
      </div>
      <div class="box" data-inview="fadeInUp" data-delay="400">
        <img src="@/assets/img/fizz-icon.svg" alt="Fizz" />
        <p class="regular-text">
          {{ $t('Mobile carrier and ISP 100% DIGITAL EXPERIENCE since 2018') }}
        </p>
      </div>
      <div class="box" data-inview="fadeInUp" data-delay="600">
        <img src="@/assets/img/trophee-icon.svg" alt="3" />
        <p class="regular-text">
          {{ $t('Videotron ranks first in customer experience among Canadian wireless providers according to') }}
          <a href="https://www.forrester.com/bold" target="_blank">{{ $t("Forrester's Canada Customer Experience Index 2018.") }}</a>
        </p>
      </div>
      <div class="box" data-inview="fadeInUp" data-delay="800">
        <img src="@/assets/img/badge-icon.svg" alt="3" />
        <p class="regular-text">
          {{ $t('Fizz is first in online experience in the telecommunications industry for the third year in a row (Leger WOW Digital study)') }}
        </p>
      </div>
    </section>

    <section class="meetings">
      <h2 class="title" data-inview="fadeIn" data-delay="200">{{ $t('Meetings schedule') }}</h2>
      <p class="regular-text bold intro" data-inview="fadeIn" data-delay="400">
        {{
          $t(
            'To share our vision of what the mobile marketplace might be with more competition, we are planning a series of meetings in British Columbia, Manitoba, Alberta, and Ontario in the next couple of weeks.'
          )
        }}
      </p>
      <div class="meeting" data-inview="fadeInUp" data-delay="200">
        <p class="regular-text bold">{{ $t('British Columbia') }}</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'en'" class="regular-text bold">October 25, 2021</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'fr'" class="regular-text bold">25 Octobre 2021</p>
      </div>
      <div class="meeting light" data-inview="fadeInUp" data-delay="300">
        <p class="regular-text bold">{{ $t('British Columbia') }}</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'en'" class="regular-text bold">October 26, 2021</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'fr'" class="regular-text bold">26 Octobre 2021</p>
      </div>
      <div class="meeting" data-inview="fadeInUp" data-delay="400">
        <p class="regular-text bold">Alberta</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'en'" class="regular-text bold">October 27, 2021</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'fr'" class="regular-text bold">27 Octobre 2021</p>
      </div>
      <div class="meeting light" data-inview="fadeInUp" data-delay="500">
        <p class="regular-text bold">Alberta</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'en'" class="regular-text bold">October 28, 2021</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'fr'" class="regular-text bold">28 Octobre 2021</p>
      </div>
      <div class="meeting" data-inview="fadeInUp" data-delay="600">
        <p class="regular-text bold">Toronto, Canadian Telecom Summit</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'en'" class="regular-text bold">November 16, 2021</p>
        <p v-if="$route.meta.lang && $route.meta.lang === 'fr'" class="regular-text bold">16 Novembre 2021</p>
      </div>
    </section>

    <div class="green-wrap">
      <section class="news">
        <div class="title-wrap" data-inview="fadeIn" data-delay="200">
          <h2 class="title">{{ $t('Updates') }}</h2>
          <div class="cta-wrap">
            <div class="social">
              <p class="regular-text">{{ $t('Join us on:') }}</p>
              <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook" /></a>
              <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter" /></a>
            </div>
          </div>
        </div>

        <!-- EN -->
        <div v-if="$route.meta.lang && $route.meta.lang === 'en'" class="box-wrap">
          <a
            href="https://policyoptions.irpp.org/magazines/february-2022/we-need-to-talk-about-canadas-painful-lack-of-competition/"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="@/assets/img/news-competition.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">We need to talk about Canada’s painful lack of competition</h3>
              <div class="bottom">
                <p class="regular-text">February 21, 2022</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://www.canada.ca/en/innovation-science-economic-development/news/2022/01/government-of-canada-delivers-on-commitment-to-reduce-cell-phone-wireless-plans-by-25.html"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="@/assets/img/news-5.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Government of Canada delivers on commitment to reduce cell phone wireless plans by 25%</h3>
              <div class="bottom">
                <p class="regular-text">January 28, 2022</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://www.iphoneincanada.ca/carriers/videotron-in-alberta-means-lower-prices-more-competition-ceo/"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="@/assets/img/news-10.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Vidéotron in Alberta Means Lower Prices, More Competition: CEO</h3>
              <div class="bottom">
                <p class="regular-text">November 17, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://financialpost.com/telecom/peladeau-slams-telecom-oligopoly-as-quebecor-pushes-to-become-fourth-national-wireless-player"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="@/assets/img/news-9.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Péladeau slams telecom 'oligopoly' as Quebecor pushes to be fourth national wireless player</h3>
              <div class="bottom">
                <p class="regular-text">November 16, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://edmontonsun.com/business/albertans-would-benefit-in-upcoming-cellphone-war-says-videotron-boss/wcm/f548f78d-c194-4f7c-adbe-6eadafa217d6"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="@/assets/img/news-8.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Challenge to Canada's big three cellphone carriers may benefit Alberta, says Videotron boss</h3>
              <div class="bottom">
                <p class="regular-text">October 29, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a
            href="https://mobilesyrup.com/2021/10/26/its-official-videotron-wants-to-expand-into-western-canada/"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="400"
          >
            <div class="img-wrap"><img src="@/assets/img/news-7.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">It’s official: Vidéotron wants to expand into Western Canada</h3>
              <div class="bottom">
                <p class="regular-text">October 26, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a
            href="https://vancouversun.com/news/local-news/videotron-boss-starts-sales-pitch-for-companys-western-wireless-ambitions"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="600"
          >
            <div class="img-wrap"><img src="@/assets/img/news-6.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Videotron boss starts sales pitch for company's Western wireless ambitions</h3>
              <div class="bottom">
                <p class="regular-text">October 25, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a
            href="https://www.quebecor.com/en/-/d-c3-a9claration-de-qu-c3-a9becor-et-vid-c3-a9otron-c3-a0-la-suite-du-rejet-en-cour-f-c3-a9d-c3-a9rale-de-la-contestation-de-telus-pour-bloquer-l-ach"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="800"
          >
            <div class="img-wrap"><img src="@/assets/img/news-4.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">Research and Studies</p>
              <h3 class="title">
                Statement by Quebecor and Videotron on the Federal Court decision to dismiss Telusâ€™s legal challenge to the purchase of 5G spectrum in
                Western Canada
              </h3>
              <div class="bottom">
                <p class="regular-text">October 22, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a
            href="https://nationalpost.com/news/canada/canadas-wireless-costs-continue-to-be-the-highest-or-among-the-highest-in-the-world-finnish-report"
            target="_blank"
            class="news-box"
          >
            <div class="img-wrap"><img src="@/assets/img/news-3.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">Research and Studies</p>
              <h3 class="title">Canada's wireless costs 'continue to be the highest or among the highest in the world': Finnish report</h3>
              <div class="bottom">
                <p class="regular-text">October 10, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a
            href="https://www.quebecor.com/en/-/qu-c3-a9becor-et-vid-c3-a9otron-e2-80-89-un-pas-de-plus-vers-l-expansion-hors-qu-c3-a9bec"
            target="_blank"
            class="news-box"
          >
            <div class="img-wrap"><img src="@/assets/img/news-5.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Quebecor and Videotron take another step towards expansion outside Quebec</h3>
              <div class="bottom">
                <p class="regular-text">July 29, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a href="https://www.bnnbloomberg.ca/quebecor-ceo-eyes-freedom-mobile-to-expand-outside-quebec-1.1618330" target="_blank" class="news-box">
            <div class="img-wrap"><img src="@/assets/img/news-1.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Quebecor CEO eyes Freedom Mobile to expand outside Quebec</h3>
              <div class="bottom">
                <p class="regular-text">June 17, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
          <a
            href="https://www.theglobeandmail.com/business/article-quebecor-ceo-sets-his-sights-on-expansion-outside-quebec/"
            target="_blank"
            class="news-box"
          >
            <div class="img-wrap"><img src="@/assets/img/news-2.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">News</p>
              <h3 class="title">Quebecor CEO sets his sights on expansion outside Quebec</h3>
              <div class="bottom">
                <p class="regular-text">May 13, 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
        </div>

        <!-- FR -->
        <div v-if="$route.meta.lang && $route.meta.lang === 'fr'" class="box-wrap">
          <a
            href="https://policyoptions.irpp.org/fr/magazines/february-2022/on-doit-parler-du-manque-de-concurrence-au-canada/"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap">
              <img src="@/assets/img/news-competition.jpg" alt="News" />
            </div>
            <div class="data">
              <p class="small-title">Nouvelles</p>
              <h3 class="title">On doit parler du manque de concurrence au Canada</h3>
              <div class="bottom">
                <p class="regular-text">21 février 2022</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://www.journaldemontreal.com/2022/01/28/services-sans-fil-ottawa-atteint-son-engagement-de-reduction-du-prix"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap">
              <img
                src="https://m1.quebecormedia.com/emp/emp/b3680eb7-525c-4e30-b3f5-854fa1ca18f7/cute-girl-texting-with-her-mobile_ORIGINAL.jpg?impolicy=crop-resize&x=0&y=0&w=5515&h=3102&width=968"
                alt="News"
              />
            </div>
            <div class="data">
              <p class="small-title">Nouvelles</p>
              <h3 class="title">Services sans fil: Ottawa atteint son engagement de réduction du prix</h3>
              <div class="bottom">
                <p class="regular-text">28 Janvier 2022</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://ici.radio-canada.ca/ohdio/premiere/emissions/phare-ouest/segments/entrevue/376260/videotron-pierre-karl-peladeau-cellullaire"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap">
              <img
                src="https://images.radio-canada.ca/q_auto,w_960/v1/ici-premiere/16x9/phare-ouest-telephone-cellilaire-intelligent-femme-nuit.jpg"
                alt="News"
              />
            </div>
            <div class="data">
              <p class="small-title">Nouvelles</p>
              <h3 class="title">Vidéotron pourrait-il devenir le 4e grand joueur en mobilité en C.-B.?</h3>
              <div class="bottom">
                <p class="regular-text">25 Octobre 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://www.tvanouvelles.ca/2021/10/22/encheres-du-spectre-3500-mhz-dans-louest-la-cour-federale-se-range-du-cote-de-videotron"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap">
              <img
                src="https://m1.quebecormedia.com/emp/emp/a8d1abe4-13d9-473b-8d78-c04f6db5820b/3g-4g-und-5g-mobilfunkstandort-telekommunikationsturm-funkturm-oder-mobilfunkbas_ORIGINAL.jpg?impolicy=crop-resize&x=0&y=0&w=5000&h=3333&width=940"
                alt="News"
              />
            </div>
            <div class="data">
              <p class="small-title">Nouvelles</p>
              <h3 class="title">Enchères du spectre 3500 MHz dans l’Ouest: la Cour fédérale se range du côté de Vidéotron</h3>
              <div class="bottom">
                <p class="regular-text">22 Octobre 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://www.ledevoir.com/economie/621711/quebecor-sort-gagnant-des-encheres-dans-le-sans-fil"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="https://media2.ledevoir.com/images_galerie/nwd_966090_778380/image.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">Nouvelles</p>
              <h3 class="title">Québecor sort grand gagnant des enchères de fréquences de télécommunication sans fil</h3>
              <div class="bottom">
                <p class="regular-text">31 Juillet 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>

          <a
            href="https://www.quebecor.com/fr/-/qu-c3-a9becor-et-vid-c3-a9otron-e2-80-89-un-pas-de-plus-vers-l-expansion-hors-qu-c3-a9bec"
            target="_blank"
            class="news-box"
            data-inview="fadeInUp"
            data-delay="200"
          >
            <div class="img-wrap"><img src="@/assets/img/news-7.jpg" alt="News" /></div>
            <div class="data">
              <p class="small-title">Nouvelles</p>
              <h3 class="title">QUÉBECOR ET VIDÉOTRON: UN PAS DE PLUS VERS L’EXPANSION HORS QUÉBEC</h3>
              <div class="bottom">
                <p class="regular-text">29 Juillet 2021</p>
                <img src="@/assets/img/exit.svg" alt="Exit" />
              </div>
            </div>
          </a>
        </div>

        <div class="swipe">
          <img src="@/assets/img/swipe-left.svg" alt="Swipe" />
          <p class="regular-text bold">{{ $t('Swipe') }}</p>
        </div>
      </section>
    </div>

    <footer class="footer">
      <div class="form-wrap" data-inview="slideRight" data-delay="200">
        <h2 class="title">{{ $t('Comments? Share!') }}</h2>
        <form
          :action="$route.meta.lang && $route.meta.lang === 'en' ? '/success' : '/fr/success'"
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />

          <input type="text" name="First and last name" :placeholder="$t('First and last name')" />
          <input type="email" name="Email" :placeholder="$t('Email')" required />
          <textarea id="message" name="message" placeholder="Message" required></textarea>
          <input type="submit" :value="$t('Send')" />
        </form>
      </div>
      <div class="twitter-wrap" data-inview="slideLeft" data-delay="200">
        <a class="twitter-timeline" data-height="600" href="https://twitter.com/Lowermybillca?ref_src=twsrc%5Etfw">{{ $t('Tweets by Lowermybillca') }}</a>
      </div>
      <div class="bottom" data-inview="fadeIn" data-delay="200">
        <p class="regular-text">{{ $t('© All right reserved - Quebecor') }}</p>
        <div class="social">
          <p class="regular-text">{{ $t('Follow us on:') }}</p>
          <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook" /></a>
          <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter" /></a>
        </div>
      </div>
    </footer>

    <a href="https://elmire.ca/" target="_blank" class="credit">
      <p class="regular-text">Conception Elmire</p>
      <img src="@/assets/img/elmire.svg" alt="Elmire" />
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'The Canadian market needs a shake up!',
      },
      meta: [
        {
          name: 'description',
          content: 'Don’t let the big three mobile providers block lower prices.',
          id: 'desc',
        },
        { name: 'application-name', content: 'The Canadian market needs a shake up!' },
        { name: 'twitter:title', content: 'Don’t let the big three mobile providers block lower prices.' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Don’t let the big three mobile providers block lower prices.',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'The Canadian market needs a shake up!' },
        { property: 'og:site_name', content: 'The Canadian market needs a shake up!' },
        {
          property: 'og:description',
          content: 'Don’t let the big three mobile providers block lower prices.',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://www.lowermybill.ca/img/og-image.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://www.lowermybill.ca/img/og-image.jpg',
        },
      ],
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/animations-layer.scss';
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/typos.scss';
@import '@/theme/form.scss';

.hero {
  position: relative;
  width: 100%;
  background-image: url('../assets/img/hero-banner.jpg');
  background-size: cover;
  background-position: center top;
  text-align: center;
  padding: 35vw 5vw 3vw 5vw;

  &.is-fr {
    background-image: url('../assets/img/hero-banner-fr.jpg');
  }

  .play {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -60%) scale(1, 1);
    width: 60px;
    transition: all 0.2s ease;
    z-index: 1000;

    &:hover {
      transform: translate(-50%, -60%) scale(0.9, 0.9);
    }
  }

  .arrow {
    width: 20px;
    display: inline-block;
    position: relative;
    top: 0;
    animation-name: arrow;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes arrow {
    from {
      top: 0;
    }
    to {
      top: 10px;
    }
  }

  .data {
    position: relative;
    z-index: 10;
    width: 100%;
    margin: 0 auto;

    .title {
      color: #fff;
    }

    .regular-text {
      font-size: 20px;
      color: $color2;
      margin: 1vw 0;
    }
  }

  .overlay {
    width: 100%;
    height: 20vw;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(9, 35, 28, 1) 100%);
    z-index: 1;
    opacity: 0.2;
  }

  @media screen and (max-width: 1100px) {
    padding: 38vw 5vw 3vw 5vw;
    .data {
      .regular-text {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 75vw 5vw 8vw 5vw;
    background-image: url('../assets/img/hero-banner-mobile.jpg');
    .play {
      width: 48px;
    }
    .overlay {
      height: 80vw;
    }
    .data {
      .regular-text {
        margin: 3vw 0;
        font-size: 14px;
      }
    }
  }
}

.split {
  width: 100%;
  padding: 5vw 8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.reverse {
    flex-direction: row-reverse;
  }

  .data {
    width: 46%;

    .regular-text {
      margin-top: 20px;

      &.bigger {
        font-size: 24px;
      }
      span {
        font-weight: 700;
      }
    }

    .cta-wrap {
      display: inline-block;
      margin-top: 20px;

      .regular-text {
        margin-top: 0;
      }
    }
  }

  .form-wrap {
    width: 46%;
    background-color: $color1;
    padding: 5vw;

    .title {
      color: $color3;
    }

    .regular-text {
      color: $color2;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  .img {
    width: 46%;

    .label {
      width: 100%;

      .regular-text {
        font-size: 12px;

        &.biggest {
          font-size: 20px;

          @media screen and (max-width: 900px) {
            font-size: 14px;
          }
        }

        span {
          font-weight: 700;
        }
      }
    }

    .bloc {
      margin-top: 2vw;
      text-align: center;
      width: 100%;

      .regular-text {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    padding: 8vw;
    .data {
      width: 100%;
      order: 1;

      .regular-text {
        &.bigger {
          font-size: 16px;
        }
      }
    }

    .form-wrap {
      width: 100%;
      order: 0;
      margin-bottom: 5vw;
    }

    .img {
      width: 100%;
      order: 0;
      margin-bottom: 5vw;
    }
  }
}

.green-wrap {
  width: 100%;
  background-color: $color2;
}

.top-arguments {
  width: 100%;
  padding: 0 8vw 8vw 8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .title {
    width: 100%;
    padding-right: 40vw;
    margin-bottom: 3vw;
    padding-right: 34vw;
  }

  .box {
    width: 23%;

    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .regular-text {
      color: $color1;
      width: 100%;
      text-align: center;
      font-weight: 700;

      a {
        text-decoration: underline;
        color: $color3;
        font-weight: 700;
      }

      &.bold {
        font-size: 18px;
        color: $color3;
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 0 8vw 8vw 8vw;
    .title {
      padding-right: 0;
      margin-bottom: 5vw;
    }
  }
  @media screen and (max-width: 800px) {
    .box {
      width: 100%;
      margin-bottom: 3vw;
      padding: 5vw;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .box {
      .regular-text {
        &.bold {
          font-size: 16px;
        }
      }
    }
  }
}

.letter {
  width: 100%;
  padding: 5vw 8vw 0 8vw;

  .img {
    img.desktop {
      display: block;
    }
    img.mobile {
      display: none;
    }
  }

  .data {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 5vw 5vw 0 5vw;
    position: relative;
    top: -5vw;

    .regular-text {
      a {
        color: $color3;
        text-decoration: underline;
        font-weight: 700;
      }
    }

    .title {
      margin-bottom: 20px;
      width: 100%;
    }

    .cta-wrap {
      margin-top: 20px;
      display: inline-block;
    }
  }

  @media screen and (max-width: 900px) {
    padding: 8vw;
    .data {
      top: 0;
      width: 100%;
      padding: 5vw 0 0 0;
      .left {
        width: 100%;
        margin-bottom: 5vw;
      }
      .right {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .img {
      img.desktop {
        display: none;
      }
      img.mobile {
        display: block;
      }
    }
  }
}

.meetings {
  width: 100%;
  padding: 0 8vw 5vw 8vw;

  .title {
    margin-bottom: 20px;
  }

  .intro {
    width: 60%;
    margin-bottom: 40px;
  }

  .meeting {
    width: 100%;
    background-color: $color2;
    display: flex;
    justify-content: space-between;
    padding: 2vw 3vw;

    &.light {
      background-color: #f3f8f1;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0 8vw 8vw 8vw;
    .intro {
      width: 100%;
      margin-bottom: 30px;
    }
    .meeting {
      flex-wrap: wrap;
      .regular-text {
        width: 100%;
        line-height: 1.8;
      }
    }
  }
}

.news {
  width: 100%;
  padding: 5vw 0 5vw 8vw;

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-right: 8vw;
  }

  .swipe {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      width: 30px;
      margin-right: 10px;
    }

    .regular-text {
      font-size: 14px;
    }
  }

  .box-wrap {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    padding-bottom: 20px;
    padding-right: calc(8vw - 20px);

    .news-box {
      width: 29%;
      min-width: 29%;
      margin-right: 20px;

      &:hover {
        .img-wrap {
          img {
            transform: scale(1.1, 1.1);
          }
        }
      }

      .img-wrap {
        overflow: hidden;
        height: 15vw;
        border-bottom: 1px solid rgba(#f2f2f2, 1);

        img {
          width: 100%;
          height: 15vw;
          object-fit: cover;
          transform: scale(1, 1);
          transition: all 0.5s ease;
        }

        @media screen and (max-width: 767px) {
          &,
          & img {
            height: 170px;
          }
        }
      }

      .data {
        background-color: #fff;
        padding: 2vw 3vw;

        .title {
          font-size: 20px;
          min-height: 150px;
        }

        .small-title {
          color: $color3;
          margin-bottom: 5px;
          font-family: 'DM Serif Display', serif;
          font-size: 18px;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;

          .regular-text {
            font-weight: 700;
            font-size: 14px;
          }

          img {
            width: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    padding: 8vw 0 8vw 8vw;
    .box-wrap {
      .news-box {
        width: 44%;
        min-width: 44%;
        .data {
          padding: 5vw;
          .title {
            font-size: 18px;
            min-height: 70px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .title-wrap {
      .cta-wrap {
        display: none;
      }
    }
    .box-wrap {
      .news-box {
        width: 75%;
        min-width: 75%;
        .data {
          padding: 5vw;
          .title {
            font-size: 16px;
            min-height: 80px;
          }
          .bottom {
            img {
              width: 18px;
            }
          }
        }
      }
    }
  }
}

.footer {
  width: 100%;
  padding: 5vw 8vw;
  background-color: $color1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .form-wrap {
    width: 35%;
    .title {
      color: $color3;
      margin-bottom: 20px;
    }
  }

  .twitter-wrap {
    width: 50%;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vw;

    .regular-text {
      color: $color3;
      font-weight: 700;
    }

    .social {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 8vw;
    .form-wrap {
      width: 100%;
      margin-bottom: 5vw;
    }
    .twitter-wrap {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .bottom {
      flex-wrap: wrap;
      .regular-text {
        width: 100%;
      }
      .social {
        width: 100%;
        margin-top: 5vw;
      }
    }
  }
}

.sources {
  padding: 3vw 8vw;

  .regular-text {
    font-size: 12px;
    margin-bottom: 10px;
    a {
      color: $color3;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 8vw;
    .regular-text {
      margin-bottom: 3vw;
    }
  }
}

.credit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  img {
    width: 25px;
    margin-left: 10px;
  }
}
</style>

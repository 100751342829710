const trans = {
  en: {
    '': '',
  },
  fr: {
    'Follow us on:': 'Suivez-nous sur:',

    'More competition is needed': 'Nous avons besoin de plus de compétition',
    'Canadians deserve better!': 'Les Canadiens méritent mieux !',
    'Most Canadians pay a lot for their mobile phone service. To get better rates, experts agree:<span> the presence of a new player, a strong 4th player, in a mobile market is essential to increase competition and lower prices.</span>.<br><br><span>Videotron</span>, a leader in telecommunications in Quebec, is determined to <span>shake up the market</span>. As a strong and solid 4th player, we are ready to expand our services and give the Big 3 some healthy competition in Western Canada and Ontario.':
      'La majorité des Canadiens paient le fort prix pour leur service sans fil. <br><br> Pour obtenir de meilleurs prix, les experts s’entendent: <span>la présence d’un nouveau joueur, un 4e joueur solide, dans le marché du sans fil est essentielle pour accroître la compétition et faire baisser les prix.</span> <br><br> <span>Vidéotron</span>, un leader des télécommunications au Québec, est déterminé <span>à secouer le marché.</span> <br><br> En tant que 4e joueur fort et solide, nous sommes prêts à étendre nos services et à créer une véritable et créer une saine concurrence en Ontario et dans l’Ouest canadien.',
    'Subscribe for email updates': 'Abonnez-vous pour rester informé de nos dernières actualités.',

    'Competition from Videotron helps lower wireless prices':
      'Une présence forte de Vidéotron dans le marché contribue à faire baisser les prix du sans fil',
    'Advantage for consumers': 'Bénéfice pour les consommateurs ',
    'Where there is a strong 4th player, <span>Canadians pay 35-40% less than elsewhere in the country</span>, according to the Competition Bureau of Canada (2019)':
      'Là où se trouve un 4e joueur solide, les Canadiens paient de 35 à 40 % moins cher qu’ailleurs au pays, selon le Bureau de la concurrence du Canada (2019)',
    'Target achieved': 'Cible atteinte ',
    'Thanks to Videotron, Quebec is the first part of the country to achieve the government’s <span>25% price reduction</span> target for specific wireless plans':
      'Grâce à Vidéotron, le Québec est le premier endroit au pays à connaître une baisse des prix de 25 % pour certains forfaits sans fil, atteignant ainsi la cible gouvernementale ',
    'Join us on:': 'Suivez-nous sur:',
    'Why lack of competition matters': 'Exemple du manque de concurrence',
    'While advertising in Canadian newspapers, Bell is proposing in Vancouver, Edmonton, Calgary and Ottawa a 20gb wireless plan for 80$/month. In Montreal, Bell offers the same plan at 65$/month, which just happens to be what Videotron charges.':
      'Dans ses publicités dans les journaux canadiens, Bell propose à Vancouver, Edmonton, Calgary et Ottawa un forfait sans fil de 20 Go pour 80 $/mois. À Montréal, Bell offre le même forfait à 65 $/mois, ce qui correspond justement aux tarifs de Vidéotron.',
    'Acquisition of spectrum by Videotron across Canada': 'Acquisition de blocs de spectre à travers le Canada',
    'Videotron invested $830 million in the last 5G spectrum auction to be able to move forward on its plans to bring healthy competition, more choices and lower prices to more Canadians.':
      'Vidéotron a investi 830 millions $ dans la dernière vente aux enchères du spectre 5G pour réaliser son ambition d’apporter une concurrence saine à un plus grand nombre de Canadiens.',

    'Who we are': 'Qui sommes-nous ?',
    'is an integrated communications company engaged in cable television, interactive multimedia development, Internet access, cable telephone and wireless telephone services. As a Quebecor company, established for more than five decades, Videotron is part of a strong, creative, and diversified family business.':
      'est une société intégrée de communications œuvrant dans les domaines de la télédistribution, du développement multimédia interactif, des services d’accès Internet, de la téléphonie et de la téléphonie sans fil. ',
    'As Quebec’s most respected telecommunications provider for the last 16 years (2021 Léger Reputation survey), Videotron broke the Big 3 oligopoly in Quebec by becoming a 4th major player in the wireless market, offering competitive mobile phone service there since 2006.':
      "En tant que société de Québecor, établie depuis plus de cinq décennies, Vidéotron fait partie d'une entreprise familiale solide, créative et diversifiée.",
    'The company has invested billions over decades to create distinctive offerings and services, and to build out a reliable, powerful, robust telecommunications network. Videotron was even the first in the country to create an all-digital telecom brand with Fizz.':
      "L’entreprise de télécommunications la plus admirée des Québécois depuis 16 ans (sondage Léger Réputation 2021), Vidéotron est responsable de la fin de l'oligopole des 3 grands fournisseurs de téléphonie mobile au Québec en offrant, depuis 2006, les services d'un nouvel opérateur sans fil et en devenant un 4e joueur solide.",
    '$3B+ INVESTED in wireless': '$3G+ INVESTIS dans le sans fil ',
    'Mobile carrier and ISP 100% DIGITAL EXPERIENCE since 2018': 'Fournisseur de téléphonie mobile et Internet, expérience 100 % numérique depuis 2018',
    'Videotron ranks first in customer experience among Canadian wireless providers according to':
      "Vidéotron se classe au premier rang pour l'expérience client parmi les fournisseurs de services mobiles canadiens, selon",
    "Forrester's Canada Customer Experience Index 2018.": "l'indice d'expérience de la clientèle de 2018 de Forrester.",
    'Fizz is first in online experience in the telecommunications industry for the third year in a row (Leger WOW Digital study)':
      'Fizz arrive premier pour l’expérience en ligne dans le secteur des télécommunications pour la troisième année consécutive (indices WOW numérique Léger).',

    'Meetings schedule': 'CALENDRIER DES RÉUNIONS',
    'To share our vision of what the mobile marketplace might be with more competition, we are planning a series of meetings in British Columbia, Manitoba, Alberta, and Ontario in the next couple of weeks.':
      'Pour partager notre vision de ce que pourrait être le marché de la téléphonie mobile avec plus de compétition, nous prévoyons une série de rencontres en Colombie-Britannique, au Manitoba, en Alberta et en Ontario au cours des prochaines semaines.',
    'British Columbia': 'Colombie-Britannique',

    Updates: 'Nouvelles',
    Swipe: 'Défiler',
    'Comments? Share!': 'Des commentaires ? Partagez',

    'First and last name': 'Prénom et Nom',
    Email: 'Courriel',
    Send: 'Envoyer',
    '© All right reserved - Quebecor': '© Tous droits réservés - Quebecor',
    'Tweets by Lowermybillca': '',

    'Thank you!': 'Merci!',
    'Your message has been sent successfully. We will answer you shortly.': 'Merci! Votre message a été envoyé avec succès.',
    Home: 'Accueil',
  },
}

export default trans

<template>
  <div class="page-wrapper">

    <footer class="footer">
      <div class="form-wrap" data-inview="slideRight" data-delay="200">
        <h2 class="title">{{ $t("Thank you!") }}</h2>
        <p class="regular-text bold bigger">{{ $t("Your message has been sent successfully. We will answer you shortly.") }}</p>
        <div class="cta-wrap">
            <a href="/" target="_blank" class="cta">
              <p class="regular-text bold">{{ $t("Home") }}</p>
            </a>
          </div>
      </div>
      <div class="twitter-wrap" data-inview="slideLeft" data-delay="200">
        <a class="twitter-timeline" data-height="600" href="https://twitter.com/Lowermybillca?ref_src=twsrc%5Etfw">{{ $t("Tweets by Lowermybillca") }}</a>
      </div>
      <div class="bottom" data-inview="fadeIn" data-delay="200">
        <p class="regular-text">{{ $t("© All right reserved - Quebecor") }}</p>
        <div class="social">
          <p class="regular-text">{{ $t("Follow us on:") }}</p>
          <a href="https://www.facebook.com/lowermybillca/" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook"></a>
          <a href="https://twitter.com/Lowermybillca" target="_blank"><img src="@/assets/img/twitter.svg" alt="Twitter"></a>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'The Canadian market needs a shake up!'
      },
      meta: [
        {
          name: 'description',
          content: 'Don’t let the big three mobile providers block lower prices.',
          id: 'desc'
        },
        { name: 'application-name', content: 'The Canadian market needs a shake up!' },
        { name: 'twitter:title', content: 'Don’t let the big three mobile providers block lower prices.'},
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Don’t let the big three mobile providers block lower prices.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'The Canadian market needs a shake up!'},
        { property: 'og:site_name', content: 'The Canadian market needs a shake up!' },
        {
          property: 'og:description',
          content: 'Don’t let the big three mobile providers block lower prices.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://lower-my-bill.netlify.app/img/og-image.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://lower-my-bill.netlify.app/img/og-image.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted () {
    this.lightboxelement = GLightbox
    GLightbox()
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/animations-layer.scss';
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/typos.scss';
@import '@/theme/form.scss';


.hero {
  position: relative;
  width: 100%;
  background-image: url("../assets/img/hero-banner.jpg");
  background-size: cover;
  background-position: center top;
  text-align: center;
  padding: 35vw 5vw 3vw 5vw;

  .play {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-60%) scale(1,1);
    width: 60px;
    transition: all 0.2s ease;
    z-index: 1000;

    &:hover {
      transform: translate(-50%,-60%) scale(0.9,0.9);
    }
  }

  .arrow {
    width: 20px;
    display: inline-block;
    position: relative;
    top: 0;
    animation-name: arrow;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes arrow {
    from {top: 0;}
    to {top: 10px;}
  }

  .data {
    position: relative;
    z-index: 10;
    width: 100%;
    margin: 0 auto;

    .title {
      color: #fff;
    }

    .regular-text {
      font-size: 20px;
      color: $color2;
      margin: 1vw 0;
    }
  }

  .overlay {
    width: 100%;
    height: 20vw;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(9,35,28,1) 100%);
    z-index: 1;
    opacity: 0.2;
  }

  @media screen and (max-width: 1100px) {
    padding: 38vw 5vw 3vw 5vw;
    .data {
      .regular-text {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 75vw 5vw 8vw 5vw;
    background-image: url("../assets/img/hero-banner-mobile.jpg");
    .play {
      width: 48px;
    }
    .overlay {
      height: 80vw;
    }
    .data {
      .regular-text {
        margin: 3vw 0;
        font-size: 14px;
      }
    }
  }
}

.split {
  width: 100%;
  padding: 5vw 8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .data {
    width: 46%;

    .regular-text {
      margin-top: 20px;
      &.bigger {
        font-size: 24px;
      }
      span {
        font-weight: 700;
      }
    }

    .cta-wrap {
      display: inline-block;
      margin-top: 20px;

      .regular-text {
        margin-top: 0;
      }
    }
  }

  .form-wrap {
    width: 46%;
    background-color: $color1;
    padding: 5vw;

    .title {
      color: $color3;
    }

    .regular-text {
      color: $color2;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  .img {
    width: 46%;

    .label {
      width: 100%;
      padding: 15px 20px;
      background-color: #ECF5E9;

      .regular-text {
        font-size: 14px;

        span {
          font-weight: 700;
        }
      }
    }

    .bloc {
      margin-top: 2vw;
      text-align: center;
      width: 100%;

      .regular-text {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    padding: 8vw;
    .data {
      width: 100%;
      order: 1;

      .regular-text {
        &.bigger {
          font-size: 16px;
        }
      }
    }

    .form-wrap {
      width: 100%;
      order: 0;
      margin-bottom: 5vw;
    }

    .img {
      width: 100%;
      order: 0;
      margin-bottom: 5vw;
    }
  }
}

.green-wrap {
  width: 100%;
  background-color: $color2;
}

.top-arguments {
  width: 100%;
  padding: 0 8vw 8vw 8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .title {
    width: 100%;
    padding-right: 40vw;
    margin-bottom: 3vw;
    padding-right: 34vw;
  }

  .box {
    width: 23%;

    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .regular-text {
      color: $color1;
      width: 100%;
      text-align: center;
      font-weight: 700;

      a {
        text-decoration: underline;
        color: $color3;
        font-weight: 700;
      }

      &.bold {
        font-size: 18px;
        color: $color3;
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 0 8vw 8vw 8vw;
    .title {
      padding-right: 0;
      margin-bottom: 5vw;
    }
  }
  @media screen and (max-width: 800px) {
    .box {
      width: 100%;
      margin-bottom: 3vw;
      padding: 5vw;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .box {
      .regular-text {
        &.bold {
          font-size: 16px;
        }
      }
    }
  }
}

.letter {
  width: 100%;
  padding: 5vw 8vw 0 8vw;

  .img {
    img.desktop {
      display: block;
    }
    img.mobile {
      display: none;
    }
  }

  .data {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 5vw 5vw 0 5vw;
    position: relative;
    top: -5vw;

    .regular-text {
      a {
        color: $color3;
        text-decoration: underline;
        font-weight: 700;
      }
    }

    .title {
      margin-bottom: 20px;
      width: 100%;
    }

    .cta-wrap {
      margin-top: 20px;
      display: inline-block;
    }
  }

  @media screen and (max-width: 900px) {
    padding: 8vw;
    .data {
      top: 0;
      width: 100%;
      padding: 5vw 0 0 0;
      .left {
        width: 100%;
        margin-bottom: 5vw;
      }
      .right {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .img {
      img.desktop {
        display: none;
      }
      img.mobile {
        display: block;
      }
    }
  }
}

.meetings {
  width: 100%;
  padding: 0 8vw 5vw 8vw;

  .title {
    margin-bottom: 20px;
  }

  .intro {
    width: 60%;
    margin-bottom: 40px;
  }

  .meeting {
    width: 100%;
    background-color: $color2;
    display: flex;
    justify-content: space-between;
    padding: 2vw 3vw;

    &.light {
      background-color: #F3F8F1;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0 8vw 8vw 8vw;
    .intro {
      width: 100%;
      margin-bottom: 30px;
    }
    .meeting {
      flex-wrap: wrap;
      .regular-text {
        width: 100%;
        line-height: 1.8;
      }
    }
  }
}

.news {
  width: 100%;
  padding: 5vw 0 5vw 8vw;

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-right: 8vw;
  }

  .swipe {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      width: 30px;
      margin-right: 10px;
    }

    .regular-text {
      font-size: 14px;
    }
  }

  .box-wrap {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    padding-bottom: 20px;
    padding-right: calc(8vw - 20px);

    .news-box {
      width: 29%;
      min-width: 29%;
      margin-right: 20px;

      &:hover {
        .img-wrap {
          img {
            transform: scale(1.1,1.1);
          }
        }
      }

      .img-wrap {
        overflow:hidden;

        img {
          transform: scale(1,1);
          transition: all 0.5s ease;
        }
      }

      .data {
        background-color: #fff;
        padding: 2vw 3vw;

        .title {
          font-size: 20px;
          min-height: 80px;
        }

        .small-title {
          color: $color3;
          margin-bottom: 5px;
          font-family: 'DM Serif Display', serif;
          font-size: 18px;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;

          .regular-text {
            font-weight: 700;
            font-size: 14px;
          }

          img {
            width: 20px;
          }
        }
      }
    }    
  }

  @media screen and (max-width: 900px) {
    padding: 8vw 0 8vw 8vw;
    .box-wrap {
      .news-box {
        width: 44%;
        min-width: 44%;
        .data {
          padding: 5vw;
          .title {
            font-size: 18px;
            min-height: 70px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .title-wrap {
      .cta-wrap {
        display: none;
      }
    }
    .box-wrap {
      .news-box {
        width: 75%;
        min-width: 75%;
        .data {
          padding: 5vw;
          .title {
            font-size: 16px;
            min-height: 80px;
          }
          .bottom {
            img {
              width: 18px;
            }
          }
        }
      }
    }
  }
}

.footer {
  width: 100%;
  padding: 5vw 8vw;
  background-color: $color1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .form-wrap {
    width: 35%;
    .title {
      color: $color3;
      margin-bottom: 20px;
    }
  }

  .regular-text {
    color: #fff;
  }

  .twitter-wrap {
    width: 50%;
  }

  .cta {
    display: inline-block;
    width: auto;
    border: 0;
    background-color: $color2;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: $color1;
    padding: 15px 20px;
    border-radius: 3px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s ease;

    .regular-text {
      color: $color1;
      padding: 0;
      margin: 0;
    }

    &:hover {
      background-color: $color3;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vw;

    .regular-text {
      color: $color3;
      font-weight: 700;
    }

    .social {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 8vw;
    .form-wrap {
      width: 100%;
      margin-bottom: 5vw;
    }
    .twitter-wrap {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .bottom {
      flex-wrap: wrap;
      .regular-text {
        width: 100%;
      }
      .social {
        width: 100%;
        margin-top: 5vw;
      }
    }
  }
}

.sources {
  padding: 3vw 8vw;

  .regular-text {
    font-size: 12px;
    margin-bottom: 10px;
    a {
      color: $color3;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 8vw;
    .regular-text {
      margin-bottom: 3vw;
    }
  }
}

</style>
